import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";

export default function useAuth() {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidUser, setIsInvalidUser] = useState(false);

  const [user, setUser] = useRecoilState(recoilStates.user);

  const { search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const isDev = process.env.NODE_ENV == "development";
    const params = new URLSearchParams(search);
    const initialToken = isDev
      ? params.get("token") || process.env.REACT_APP_TEST_TOKEN
      : params.get("token") ?? "";
    const storedToken = localStorage.getItem("auth-token") ?? "";
    const tokenToSend = initialToken ? initialToken : storedToken;
    localStorage.setItem("auth-token", tokenToSend);

    (async () => {
      setIsLoading(true);
      try {
        // const res = await createTeeshotAxiosInstance().post(`/authToken?token=${tokenToSend}`);
        const res = await createTeeshotAxiosInstance().post(`/authToken`);
        if (!res?.data?.user) {
          setIsInvalidUser(true);
          return;
        }
        // localStorage.setItem("auth-token", tokenToSend);
        setUser(res.data.user);
        navigate("/");
      } catch (e) {
        setIsInvalidUser(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return { user, isLoading, isInvalidUser };
}
