import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, MainContent, Modal, SreenCoverLoading } from "src/components";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";

export default function MainFemale() {
  return <MainContent gender="female" />;
}
