import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function KgController() {
  const { state } = useLocation();
  const [srcDoc, setSrcDoc] = useState("결제모듈 불러오는 중");
  useEffect(() => {
    setSrcDoc(state.htmlToShow);
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  const [detector, setDetector] = useState("");
  const naviagate = useNavigate();

  useEffect(() => {
    console.log("detector :", detector);
    if (!detector) {
      return;
    }
    if (detector.includes("teeshotBackDetection")) {
      naviagate("/");
    }
  }, [detector]);

  function receiveMessage(event: any) {
    // if (event.origin !== "http://example.org:8080") return;
    console.log("event :", event);
    setDetector(event.data || "");
    // ...
  }

  return (
    <Container>
      <iframe onLoad={(o: any) => {}} id="foo1" width={"100%"} srcDoc={srcDoc} />
    </Container>
  );
}

const Container = styled.div`
  #foo1 {
    height: calc(100vh - 55px);
  }
`;
