import {
  Navigate,
  Route,
  Routes,
  BrowserRouter,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import * as P from "./pages";
import { Layout, SreenCoverLoading } from "./components";
import { useAuth, useBannerId } from "./hooks";
import { useRecoilState } from "recoil";
import { recoilStates } from "./recoil/recoilStates";
import { useEffect } from "react";
export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout.MainLayout />}>
          <Route element={<PrivateRoutes />}>
            <Route path="" element={<DetectGender />} />
            <Route path="/main/m" element={<P.MainMale />} />
            <Route path="/main/f" element={<P.MainFemale />} />
            <Route path="/payment" element={<P.Payment />} />
            <Route path="/kgContoller" element={<P.KgController />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

const DetectGender = () => {
  const [user] = useRecoilState(recoilStates.user);
  const [bannerId] = useRecoilState(recoilStates.bannerId);

  const gender = user?.profile?.gender;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (gender === "male") {
      navigate("/main/m");
    }
    if (gender === "female") {
      navigate("/main/f");
    }
  }, [user]);
  return <></>;
};

const PrivateRoutes = () => {
  useBannerId();
  const { isLoading: isAuthLoading, user, isInvalidUser } = useAuth();
  if (isAuthLoading) {
    return (
      <>
        <SreenCoverLoading />
      </>
    );
  }
  if (isInvalidUser) {
    return <>유저정보 없음</>;
  }
  return <Outlet />;
};
