import { ReactNode } from "react";
import styled from "styled-components";
import Portal from "./Portal";
import { colors } from "src/styles/theme";
import Button from "./Button";

export default function Modal(props: {
  content: ReactNode;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: Function;
  rejectConfirmCondition?: boolean;
  rejectConfirmMsg?: string;
}) {
  return (
    <>
      <Portal isRootPortal={true}>
        <BackGround
          onClick={(e) => {
            props.setModalState(false);
          }}
        >
          <ModalBox
            onClick={(e) => {
              // e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="content">{props.content}</div>
            <Button
              $colorTheme="lightGreen"
              $buttonSize="md"
              onClick={() => {
                if (props.rejectConfirmCondition) {
                  alert(props.rejectConfirmMsg ? props.rejectConfirmMsg : "진행할 수 없습니다.");
                  return;
                }
                props.onConfirm && props.onConfirm();
                props.setModalState(false);
              }}
            >
              확인
            </Button>
          </ModalBox>
        </BackGround>
      </Portal>
    </>
  );
}

const BackGround = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #414141d2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 100; */
`;

const ModalBox = styled.div`
  width: 90%;
  max-width: 500px;
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;
