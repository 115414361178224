import { RecoilRoot } from "recoil";
import Router from "./Router";
import GlobalStyle from "./styles/GlobalStyles";
import InitRecoil from "./recoil/InitRecoil";
import { Meta } from "./components";
import { ReactNode } from "react";
import CacheBuster from "./CacheBuster";

function App() {
  return (
    <CacheBuster>
      <Meta />
      <RecoilRoot>
        <InitRecoil />
        <GlobalStyle />
        <Router />
      </RecoilRoot>
    </CacheBuster>
  );
}

export default App;
