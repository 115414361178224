import { atom } from "recoil";

const user = atom<any>({
  key: "user",
  default: {
    id: 0,
    realname: "",
    username: "",
    phone: "",
    gender: "",
    age: 0,
    profile: {},
  },
});

const bannerId = atom({
  key: "bannerId",
  default: {
    id: 0,
  },
});

export const recoilStates = {
  user,
  bannerId,
};
