import { Helmet } from "react-helmet-async";

export default function Meta() {
  return (
    <Helmet>
      <meta http-equiv="Expires" content="0"></meta>
      <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <title>티샷 멤버십</title>
    </Helmet>
  );
}
