import styled, { css } from "styled-components";
import Button from "./Button";
import { ComponentProps } from "react";

type DoubleButtonItemType = {
  name?: string;
  onClick?: () => void;
  widthRatio?: number;
  themeColor?: ComponentProps<typeof Button>["$colorTheme"];
  size?: ComponentProps<typeof Button>["$buttonSize"];
  buttonCompProps?: ComponentProps<typeof Button>;
};
export default function DoubleButton(props: {
  nagativeMargin?: boolean;
  left?: DoubleButtonItemType;
  right?: DoubleButtonItemType;
}) {
  return (
    <Container
      $negativeMargin={props.nagativeMargin}
      $leftFlex={props.left?.widthRatio}
      $rightFlex={props.right?.widthRatio}
    >
      <Button
        {...props.left?.buttonCompProps}
        $colorTheme={props.left?.themeColor}
        $buttonSize={props?.left?.size ?? "md"}
        onClick={() => {
          props.left?.onClick && props.left?.onClick();
        }}
      >
        {props.left?.name}
      </Button>
      <Button
        {...props.right?.buttonCompProps}
        $colorTheme={props.right?.themeColor}
        $buttonSize={props?.left?.size ?? "md"}
        onClick={() => {
          props.right?.onClick && props.right?.onClick();
        }}
      >
        {props.right?.name}
      </Button>
    </Container>
  );
}

const Container = styled.div<{
  $leftFlex?: number;
  $rightFlex?: number;
  $negativeMargin?: boolean;
}>`
  /* padding-bottom: 100px; */
  ${(p) => {
    if (p?.$negativeMargin) {
      return css`
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
      `;
    }
    return ``;
  }}
  display: flex;
  > button {
    &:nth-of-type(1) {
      flex: ${(p) => p?.$leftFlex ?? 1};
    }
    &:nth-of-type(2) {
      flex: ${(p) => p?.$rightFlex ?? 1};
    }
  }
`;
