import React, { ReactNode, useEffect } from "react";
import { recoilStates } from "./recoilStates";
import { useRecoilState } from "recoil";
import { sleep } from "src/utils";
import { createTeeshotAxiosInstance } from "src/http";

export default function InitRecoil() {
  const [user, setUser] = useRecoilState(recoilStates.user);

  useEffect(() => {
    (async () => {
      if (!user.id) {
        return;
      }
      // const groupRes = await axiosTeeshotInstance.get("/yearBooking/group");
    })();
  }, [user?.id]);

  return <></>;
}
