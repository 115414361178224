import React, { ComponentProps, useEffect } from "react";
import styled, { css } from "styled-components";
import { colors } from "src/styles/theme";

export default function Button(props: ComponentProps<typeof StyledButton>) {
  return <StyledButton {...props} />;
}
const buttonThemeObj = {
  white: {
    bgc: "#fff",
    fontColor: "black",
  },
  green: {
    bgc: colors.green.first,
    fontColor: "#fff",
  },
  lightGreen: {
    bgc: colors.green.second,
    fontColor: "#fff",
  },
  black: {
    bgc: "#000000",
    fontColor: "#fff",
  },
  gray: {
    bgc: colors.gray.first,
    fontColor: "#fff",
  },
  lightGray: {
    bgc: colors.gray.second,
    fontColor: "#000",
  },
  lightGray2: {
    bgc: colors.gray.fourth,
    fontColor: "#000",
  },
  yellow: {
    bgc: colors.yellow.first,
    fontColor: "#000",
  },
  yellow2: {
    bgc: colors.yellow.second,
    fontColor: "#000",
  },
  red: {
    bgc: colors.red.first,
    fontColor: "#fff",
  },
  pink: {
    bgc: colors.pink.first,
    fontColor: "#fff",
  },
  orange: {
    bgc: colors.orange.first,
    fontColor: "#000",
  },
  darkGreen1: {
    bgc: colors.green.third,
    fontColor: "#fff",
  },
  darkGreen2: {
    bgc: colors.green.forth,
    fontColor: "#fff",
  },
};

const buttonSizeObj = {
  sm: { padding: "5px", fontSize: "14px" },
  md: { padding: "15px", fontSize: "20px" },
};

type ButtonStyleType = {
  $round?: boolean;
  $colorTheme?: keyof typeof buttonThemeObj;
  $buttonSize?: keyof typeof buttonSizeObj;
  $pointerEventNone?: boolean;
  disabled?: boolean;
};

const StyledButton = styled.button<ButtonStyleType>`
  ${(p) => {
    if (!p.$colorTheme) {
      p.$colorTheme = "white";
    }
    if (!p.$buttonSize) {
      p.$buttonSize = "sm";
    }
    return css`
      background-color: ${buttonThemeObj[p.$colorTheme].bgc};
      color: ${buttonThemeObj[p.$colorTheme].fontColor};
      padding: ${buttonSizeObj[p.$buttonSize].padding};
      font-size: ${buttonSizeObj[p.$buttonSize].fontSize};
    `;
  }}
  ${(p) => {
    return p.$pointerEventNone
      ? css`
          pointer-events: none;
        `
      : "";
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  border-radius: ${(p) => (p.$round ? "10px" : "0px")};
  &:disabled {
    pointer-events: none;
    filter: opacity(0.7) brightness(0.7);
  }
`;
