import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Modal, SreenCoverLoading } from "src/components";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import styled, { css } from "styled-components";
import { PiCopySimpleFill } from "react-icons/pi";
import { IoCallSharp } from "react-icons/io5";
import { copyToClipBoard } from "src/utils";
import { useLongPress } from "src/hooks";

const PHONE_NUMBER = "010-9128-3489";

export default function MainContent(props: { gender: "male" | "female" }) {
  const navigate = useNavigate();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const element = document.querySelector("#top1");
    if (element) {
      element?.scrollIntoView({ block: "start" });
    }
  }, [window.location.href]);
  const [user] = useRecoilState(recoilStates.user);
  const bannerId = localStorage.getItem("banner-id");
  const [isCopied, setIsCopied] = useState(false);

  const handleClickCopy = async () => {
    await copyToClipBoard(PHONE_NUMBER);
    setIsCopied(true);
  };

  const longPressProps = useLongPress({
    onLongPress: () => {
      alert("v2222");
    },
    checkTime: 1000,
  });

  const handleClickPhoneCall = () => {
    window.open(`tel:${PHONE_NUMBER}`, "_system");
  };

  const handleClickApply = () => {
    (async () => {
      setIsLoading(true);

      try {
        if (!bannerId) {
          throw new Error("배너정보 없음");
        }
        const bannerDataRes = await createTeeshotAxiosInstance().get(`/banner/${bannerId}`);
        if (!bannerDataRes.data?.success) {
          throw new Error(bannerDataRes.data?.message || "배너정보 없음");
        }
        const res = await createTeeshotAxiosInstance().post(`/banner/interpark/${bannerId}`, {
          name: bannerDataRes.data?.data?.title,
          date: null,
          memo: null,
        });
        if (!res.data?.success) {
          console.log("res.data?.massage :", res.data?.massage);
          throw new Error("이미 신청하셨습니다.");
        }
        setIsModalOpened(true);
      } catch (e) {
        alert(`${e}`);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  return (
    <Contianer gender={props.gender} id="top1">
      {/* <div className="main-part">
        <div className="img-box">
          <img
            src="https://api.v2.teeshot.co.kr/event/resource/images/mem_temp_m.jpg"
            alt="temp-male"
          />
        </div>
      </div> */}

      <div className="main-img-box">
        <img
          src={`https://api.v2.teeshot.co.kr/event/resource/images/${
            props.gender === "female" ? "mem_f.jpg" : "mem_man1107.png"
          }`}
          alt="temp-female"
        />
      </div>
      <div className="page-pd bottom">
        <div className="contact-part">
          <div className="mt-2 d-flex ai-c jc-sb">
            ▶ 멤버쉽 컨시어지담당 이사
            <div className="v-check" {...longPressProps}></div>
          </div>
          <div className="mb-2">
            <Button $round $colorTheme="darkGreen1" onClick={() => handleClickPhoneCall()}>
              <IoCallSharp size={"24"} />
            </Button>
            {PHONE_NUMBER}{" "}
            <PiCopySimpleFill
              size={"22"}
              onClick={() => {
                handleClickCopy();
              }}
            />
            {isCopied ? <span className="copy-txt">(복사됨)</span> : null}
          </div>
        </div>

        <div className="btns">
          <Button
            // className="bold"
            $colorTheme="pink"
            $buttonSize="md"
            onClick={() => {
              // api ..

              // setIsModalOpened(true);
              handleClickApply();
            }}
          >
            {props.gender === "male" ? "무료 상담신청" : "신청하기"}
          </Button>
          {props.gender === "male" ? (
            <Button
              // className="bold"
              $colorTheme="yellow2"
              $buttonSize="md"
              onClick={() => {
                navigate("/payment");
                // alert("상담신청 후 이용해주세요.");
              }}
            >
              결제하기
            </Button>
          ) : null}
        </div>
      </div>
      {isLoading && <SreenCoverLoading />}
      {isModalOpened && (
        <Modal
          setModalState={setIsModalOpened}
          content={
            <>
              <div className="d-flex flex-1 fd-c jc-c gap-2 ai-c">
                <div>상담 신청을 남겨 주셔서 감사합니다.</div>
                <div>멤버십 담당자가 곧 전화드리도록 하겠습니다</div>
                <div>- 티샷 -</div>
              </div>
            </>
          }
        />
      )}
    </Contianer>
  );
}

const Contianer = styled.div<{ gender: any }>`
  ${(p) => {
    return css``;
  }}

  flex: 1;
  display: flex;

  .main-img-box {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 2.61;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .v-check {
    width: 30px;
    height: 30px;
    /* background-color: #fff; */
  }
  img {
    width: 100%;
  }

  .contact-part {
    color: white;
    /* font-weight: bold; */
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    > * {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .copy-txt {
      font-size: 14px;
    }
  }
  .bottom {
    background-image: ${(p) => {
      return `url("https://api.v2.teeshot.co.kr/event/resource/images/${
        p.gender === "male" ? "mem_bg_m.jpg" : "mem_bg_f.jpg"
      }")`;
    }};
    background-repeat: repeat-y;
    background-size: 100%;
    /* background-position-y: -10px; */
    background-position-y: ${(p) => (p.gender === "male" ? "-10px" : "0px")};
  }

  flex-direction: column;

  .btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
