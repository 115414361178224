import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";

function useBannerId() {
  const [bannerId, setBannerId] = useRecoilState(recoilStates.bannerId);

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const bannerId = params.get("id") ?? "";
    const storedBannerId = localStorage.getItem("banner-id") ?? "";

    const bannerIdToSend = bannerId ? bannerId : storedBannerId;
    localStorage.setItem("banner-id", bannerIdToSend);
  }, []);
}

export default useBannerId;
