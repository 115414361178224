import { useCallback, useRef, useState } from "react";

interface UseLongPressOptions {
  onLongPress: () => void;
  onClick?: () => void;
  checkTime?: number; // long-press 지속 시간 (기본값: 500ms)
}

export default function useLongPress({
  onLongPress,
  onClick,
  checkTime = 500, // default to 500ms
}: UseLongPressOptions) {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  const startPress = useCallback(() => {
    setLongPressTriggered(false);
    timerRef.current = setTimeout(() => {
      onLongPress();
      setLongPressTriggered(true);
    }, checkTime);
  }, [onLongPress, checkTime]);

  const endPress = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (!longPressTriggered && onClick) {
      onClick();
    }
  }, [onClick, longPressTriggered]);

  return {
    onMouseDown: startPress,
    onMouseUp: endPress,
    onMouseLeave: endPress,
    onTouchStart: startPress,
    onTouchEnd: endPress,
  };
}
